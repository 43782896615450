import axios from 'axios';
import { ReactComponent as AddFileSVG } from 'components/Images/AddFileSVG.svg';
import { ReactComponent as ButtonReplyCloseSVG } from 'components/Images/ButtonReplyClose.svg';
import { ReactComponent as ButtonSendMessage } from 'components/Images/ButtonSendMessage.svg';
import { ReactComponent as DeleteSvg } from 'components/Images/Delete.svg';
import { ReactComponent as EditSvg } from 'components/Images/Edit.svg';
import { ReactComponent as IconReplySVG } from 'components/Images/IconReply.svg';
import { ReactComponent as LikeSVG } from 'components/Images/Like.svg';
import { ReactComponent as ReplySvg } from 'components/Images/Reply.svg';
import { ReactComponent as SendImgSVG } from 'components/Images/SendImg.svg';
import { ReactComponent as AnimatesTypingSVG } from 'components/Images/animatedWrite.svg';
import { ReactComponent as ButtonCloseGallery } from 'components/Images/closeGalleryButton.svg';
import { ReactComponent as ButtonGallery } from 'components/Images/galleryButton.svg';
import { ReactComponent as ButtonRoomSettings } from 'components/Images/roomSettings.svg';
import { ReactComponent as ShowTypingSVG } from 'components/Images/userWrite.svg';
import DeleteRoomModal from 'components/Modal/DeleteRoomModal';
import ImageModal from 'components/Modal/ImageModal';
import { format, isToday, isYesterday } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Recorder from 'recorder-js';

import useLoginModal from '../Hooks/useLoginModal';
import Bg from '../Images/Bg_empty_chat.png';
import LoginModal from '../Modal/LoginModal';
import VerificationEmailModal from '../Modal/VerificationEmailModal';
import css from './Chat.module.css';

const Chat = () => {
  const [message, setMessage] = useState('');
  const [userList, setUserList] = useState([]);
  const [messages, setMessages] = useState([]);
  const { roomId } = useParams();
  const [roomName, setRoomName] = useState('');
  const token = localStorage.getItem('access_token');
  const messageRefs = useRef({});
  const messageContainerRef = useRef(null);
  const lastLikedMessageIdRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const currentUserId = localStorage.getItem('user_id');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFilesCount, setSelectedFilesCount] = useState(0);
  const [selectedReplyMessageId, setSelectedReplyMessageId] = useState(null);
  const [selectedReplyMessageText, setSelectedReplyMessageText] =
    useState(null);
  const [selectedReplyMessageImage, setselectedReplyMessageImage] =
    useState(null);
  const [selectedReplyMessageSender, setSelectedReplyMessageSender] =
    useState(null);
  const [imageText, setImageText] = useState('');
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [isDeleteRoomModalOpen, setIsDeleteRoomModalOpen] = useState(false);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedMessage, setEditedMessage] = useState('');
  const [isImageSending, setIsImageSending] = useState(false);
  const [isChatMenuOpen, setIsChatMenuOpen] = useState(false);
  const [showSVG, setShowSVG] = useState(false);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [scrollTopBeforeMenuOpen, setScrollTopBeforeMenuOpen] = useState(0);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [limit, setLimit] = useState(50);
  const [loadingMessageIds, setLoadingMessageIds] = useState(new Set());
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [audioStream, setAudioStream] = useState(null);

  const {
    isLoginModalOpen,
    openLoginModal,
    closeLoginModal,
    handleRegistrationSuccess,
    showVerificationModal,
    setShowVerificationModal,
  } = useLoginModal();
  const [showGallery, setShowGallery] = useState(false);
  const [menuRoomOpen, setIsMenuRoomOpen] = useState(false);

  let userName = selectedUser ? selectedUser.user_name : '';

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/rooms/`, {
          headers: {
            accept: 'application/json',
          },
        });
        const rooms = response.data;

        const room = rooms.find(room => room.id === parseInt(roomId));

        if (room) {
          setRoomName(room.name_room);
        }
      } catch (error) {
        console.error('Error fetching room data:', error);
      }
    };

    fetchRoomData();
  }, [roomId]);

  const handleDirectMessageClick = () => {
    if (!token) {
      openLoginModal();
      return;
    }

    let partnerId = selectedUser.receiver_id;
    localStorage.setItem('currentPartnerId', partnerId);

    const socket = new WebSocket(
      `wss://relativelys.eu/private/${partnerId}?token=${token}`
    );
    socket.onopen = () => {
      console.log('WebSocket connection opened');
      navigate(`/Personalchat/${userName}`);
    };
  };
  const socketRef = useRef(null);

  useEffect(() => {
    let isAnimating = false;

    if (!token) {
      axios
        .get(`${apiBaseUrl}/api/messages/${roomId}?limit=500&skip=0`)
        .then(response => {
          const arrayMessages = response.data;

          const formattedMessages = arrayMessages.reverse().map(messageData => {
            const {
              message: {
                user_name: sender = 'Unknown Sender',
                receiver_id,
                created_at,
                avatar,
                id,
                id_return,
                message,
                fileUrl,
                edited,
                deleted,
              },
            } = messageData;

            const formattedDate = formatTime(created_at);

            return {
              sender,
              avatar,
              message,
              formattedDate,
              receiver_id,
              id,
              id_return,
              fileUrl,
              edited,
              deleted,
            };
          });

          setMessages(formattedMessages);
        })
        .catch(error => {
          console.error('Error fetching messages:', error);
        });
    } else {
      const socket = new WebSocket(
        `wss://relativelys.eu/ws/${roomId}?token=${token}`
      );
      socketRef.current = socket;

      socket.onopen = () => {
        console.log('Connected to the server via WebSocket');
      };

      socket.onmessage = async event => {
        const container = messageContainerRef.current;
        const currentScrollHeight = container.scrollHeight;
        const currentScrollTop = container.scrollTop;

        try {
          const messageData = JSON.parse(event.data);
          console.log('Received message:', messageData);

          if (messageData.active_users) {
            const activeUsers = Object.values(messageData.active_users);
            setUserList(activeUsers);
          }

          if (messageData.notice === 'Load older messages') {
            setHasMoreMessages(true);
            // console.log('Есть ещё старые сообщения.');
          }
          if (messageData.notice === 'Loading all messages') {
            setHasMoreMessages(false);
            // console.log('Все сообщения загружены.');
            return;
          }
          if (messageData.deleted) {
            const messageId = messageData.deleted.id;
            console.log(messageId);

            setMessages(prevMessages => {
              return prevMessages.map(msg => {
                if (msg.id === messageId) {
                  return {
                    ...msg,
                    message: null,
                    fileUrl: null,
                    deleted: true,
                  };
                }
                return msg;
              });
            });
          }

          if (messageData.update) {
            const updatedMessage = {
              ...messageData.update,
              timestamp: new Date(messageData.update.created_at).getTime(),
            };

            setMessages(prevMessages => {
              const existingMessageIndex = prevMessages.findIndex(
                msg => msg.id === updatedMessage.id
              );

              if (existingMessageIndex !== -1) {
                // Заменяем старое сообщение обновленным
                const updatedMessages = [...prevMessages];
                updatedMessages[existingMessageIndex] = updatedMessage;
                return updatedMessages;
              }

              // Если по какой-то причине не найдено (добавляем как новое, что редко будет нужно)
              return [...prevMessages, updatedMessage];
            });
          } else if (messageData.type && !isAnimating) {
            console.log('1234:', messageData.type);
            isAnimating = true;
            setShowSVG(true);

            setTimeout(() => {
              setShowSVG(false);
              isAnimating = false;
            }, 3000);
          } else if (messageData.message) {
            // } else if (messageData.message.id || messageData.message.id === 0) {
            const {
              user_name: sender = 'Unknown Sender',
              receiver_id,
              created_at,
              avatar,
              deleted,
              message,
              id,
              id_return,
              vote,
              fileUrl,
              videoUrl,
              voiceUrl,
              room_id,
              edited,
              verified,
            } = messageData.message;
            const formattedDate = formatTime(created_at);

            const newMessage = {
              sender,
              avatar,
              message,
              id,
              id_return,
              vote,
              formattedDate,
              receiver_id,
              fileUrl,
              edited,
              verified,
              deleted,
              videoUrl,
              voiceUrl,
              room_id,
              timestamp: new Date(created_at).getTime(),
            };

            setMessages(prevMessages => {
              const existingMessageIndex = prevMessages.findIndex(
                msg => msg.id === newMessage.id
              );

              if (existingMessageIndex !== -1) {
                const updatedMessages = [...prevMessages];
                updatedMessages[existingMessageIndex] = newMessage;
                // Сортировка сообщений
                updatedMessages.sort((a, b) => a.timestamp - b.timestamp);
                return updatedMessages;
              }

              const updatedMessages = [...prevMessages, newMessage];
              // Сортировка сообщений
              updatedMessages.sort((a, b) => a.timestamp - b.timestamp);
              return updatedMessages;
            });
            // начинается отображение смс с id.return
            // if (
            //   newMessage.id_return &&
            //   !messages.find(msg => msg.id === newMessage.id_return)
            // ) {
            //   if (!loadingMessageIds.has(newMessage.id_return)) {
            //     setLoadingMessageIds(prevIds =>
            //       new Set(prevIds).add(newMessage.id_return)
            //     );

            //     try {
            //       const response = await axios.get(
            //         `${apiBaseUrl}/api/messages/message_id?message_id=${newMessage.id_return}`
            //       );
            //       const repliedMessage = response.data;

            //       const {
            //         user_name: sender = 'Unknown Sender',
            //         receiver_id,
            //         created_at,
            //         avatar,
            //         id,
            //         id_return,
            //         message,
            //         fileUrl,
            //         edited,
            //       } = repliedMessage;
            //       const formattedDate = formatTime(created_at);

            //       const formattedRepliedMessage = {
            //         sender,
            //         avatar,
            //         message,
            //         id,
            //         id_return,
            //         formattedDate,
            //         receiver_id,
            //         fileUrl,
            //         edited,
            //         timestamp: new Date(created_at).getTime(),
            //       };

            //       setMessages(prevMessages => {
            //         // Удаляем сообщения с таким же id, если они есть
            //         const filteredMessages = prevMessages.filter(
            //           msg => msg.id !== formattedRepliedMessage.id
            //         );

            //         // Добавляем новое сообщение
            //         const updatedMessages = [
            //           ...filteredMessages,
            //           formattedRepliedMessage,
            //         ];

            //         // Сортировка сообщений
            //         updatedMessages.sort((a, b) => a.timestamp - b.timestamp);

            //         return updatedMessages;
            //       });
            //     } catch (error) {
            //       console.error('Error fetching replied message:', error);
            //     } finally {
            //       setLoadingMessageIds(prevIds => {
            //         const updatedIds = new Set(prevIds);
            //         updatedIds.delete(newMessage.id_return);
            //         return updatedIds;
            //       });
            //     }
            //   }
            // }
            // заканчивается отображение смс с id.return
          } else if (messageData.messages) {
            const newMessages = messageData.messages.map(msg => ({
              sender: msg.user_name || 'Unknown Sender',
              avatar: msg.avatar,
              message: msg.message,
              id: msg.id,
              id_return: msg.id_return,
              formattedDate: formatTime(msg.created_at),
              receiver_id: msg.receiver_id,
              fileUrl: msg.fileUrl,
              edited: msg.edited,
              verified: msg.verified,
              deleted: msg.deleted,
              timestamp: new Date(msg.created_at).getTime(),
            }));

            setMessages(prevMessages => {
              const updatedMessages = [
                ...newMessages.reverse(),
                ...prevMessages,
              ];
              // Сортировка сообщений
              updatedMessages.sort((a, b) => a.timestamp - b.timestamp);
              return updatedMessages;
            });
          }
          setShouldScrollToBottom(false);
          // Обновление прокрутки
          setTimeout(() => {
            container.scrollTop =
              container.scrollHeight - currentScrollHeight + currentScrollTop;
          }, 0);
          setShouldScrollToBottom(false);
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      };

      socket.onerror = error => {
        console.error('WebSocket Error:', error);
      };

      return () => {
        if (
          socketRef.current &&
          socketRef.current.readyState === WebSocket.OPEN
        ) {
          socketRef.current.close();
        }
      };
    }
  }, [roomId, token, loadingMessageIds]);

  const loadMoreMessages = () => {
    setLimit(prevLimit => {
      const newLimit = prevLimit + 50;

      if (
        socketRef.current &&
        socketRef.current.readyState === WebSocket.OPEN
      ) {
        socketRef.current.send(
          JSON.stringify({
            limit: newLimit,
          })
        );
      }

      return newLimit;
    });
  };

  const handleScroll = () => {
    const container = messageContainerRef.current;

    if (container.scrollTop === 0 && hasMoreMessages) {
      loadMoreMessages();
    }
  };

  useEffect(() => {
    const container = messageContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMoreMessages]);

  const handleRoomMenuToggle = () => {
    if (messageContainerRef.current) {
      setScrollTopBeforeMenuOpen(messageContainerRef.current.scrollTop);
    }
    setIsMenuRoomOpen(!menuRoomOpen);
  };

  useEffect(() => {
    if (messageContainerRef.current && !menuRoomOpen) {
      messageContainerRef.current.scrollTop = scrollTopBeforeMenuOpen;
    }
  }, [menuRoomOpen]);

  useEffect(() => {
    if (shouldScrollToBottom && messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
      const timeoutId = setTimeout(() => {
        setShouldScrollToBottom(true);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [shouldScrollToBottom, messages]);

  const uploadAudioFile = async audioBlob => {
    const formData = new FormData();
    const file = new File([audioBlob], 'audio_recording.wav', {
      type: 'audio/wav',
    });
    formData.append('file', file);

    try {
      const response = await axios.post(
        `${apiBaseUrl}/api/upload-to-backblaze/chat?bucket_name=chatall`,
        formData
      );

      if (response && response.data) {
        const fileUrl = response.data;
        return fileUrl;
      } else {
        console.error('Failed to upload audio');
        return null;
      }
    } catch (error) {
      console.error('Error uploading audio:', error);
      return null;
    }
  };

  const sendMessage = async (audioBlob = null) => {
    if (!token) {
      openLoginModal();
      return;
    }

    let fileUrl = null;
    if (audioBlob) {
      fileUrl = await uploadAudioFile(audioBlob);

      if (!fileUrl) {
        console.error('Failed to upload audio.');
        return;
      }
    }

    const trimmedMessage = message.trim();
    if (!trimmedMessage && !fileUrl) {
      console.log('No message or file to send.');
      return;
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const messageObject = {
        send: {
          // original_message_id: selectedReplyMessageId || null,
          message: trimmedMessage || null,
          videoUrl: null,
          fileUrl: fileUrl || null,

          voiceUrl: null,
        },
      };

      console.log(messageObject);

      const messageString = JSON.stringify(messageObject);
      socketRef.current.send(messageString);
      setShouldScrollToBottom(true);
      setMessage('');
    } else {
      console.error('WebSocket is not open. Message not sent.');
    }
  };

  const handleMessageChange = e => {
    setMessage(e.target.value);
    setEditedMessage(e.target.value);
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ type: 'typing' }));
    }
  };

  const formatTime = created => {
    if (!created || isNaN(new Date(created))) {
      return '';
    }
    const dateTime = new Date(created);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    if (isToday(dateTime)) {
      return format(dateTime, 'HH:mm');
    } else if (isYesterday(dateTime)) {
      return `yesterday ${format(dateTime, 'HH:mm')}`;
    } else {
      return format(dateTime, 'dd MMM HH:mm');
    }
  };

  const handleAvatarClick = userData => {
    setSelectedUser(userData);
  };

  const handleLikeClick = id => {
    if (!token) {
      openLoginModal();
      return;
    }

    const requestData = {
      vote: {
        message_id: id,
        dir: 1,
      },
    };

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const messageString = JSON.stringify(requestData);
      socketRef.current.send(messageString);
      lastLikedMessageIdRef.current = id;
      console.log('Liked message ID:', lastLikedMessageIdRef.current);

      setShouldScrollToBottom(false);
    } else {
      console.error('WebSocket is not open. Message not sent.');
    }
  };

  const handleCloseMenu = () => {
    setSelectedUser(null);
  };

  const handleCloseChatMenu = () => {
    setIsChatMenuOpen(false);
  };

  const handleCloseReply = () => {
    setSelectedReplyMessageId(null);
  };

  const transliterateAndSanitize = fileName => {
    const transliterationMap = {
      А: 'A',
      Б: 'B',
      В: 'V',
      Г: 'G',
      Д: 'D',
      Е: 'E',
      Ё: 'E',
      Ж: 'Zh',
      З: 'Z',
      И: 'I',
      Й: 'Y',
      К: 'K',
      Л: 'L',
      М: 'M',
      Н: 'N',
      О: 'O',
      П: 'P',
      Р: 'R',
      С: 'S',
      Т: 'T',
      У: 'U',
      Ф: 'F',
      Х: 'Kh',
      Ц: 'Ts',
      Ч: 'Ch',
      Ш: 'Sh',
      Щ: 'Shch',
      Ы: 'Y',
      Э: 'E',
      Ю: 'Yu',
      Я: 'Ya',
      Ї: 'Yi',
      І: 'I',
      Є: 'Ye',
      Ґ: 'G',
      а: 'a',
      б: 'b',
      в: 'v',
      г: 'g',
      д: 'd',
      е: 'e',
      ё: 'e',
      ж: 'zh',
      з: 'z',
      и: 'i',
      й: 'y',
      к: 'k',
      л: 'l',
      м: 'm',
      н: 'n',
      о: 'o',
      п: 'p',
      р: 'r',
      с: 's',
      т: 't',
      у: 'u',
      ф: 'f',
      х: 'kh',
      ц: 'ts',
      ч: 'ch',
      ш: 'sh',
      щ: 'shch',
      ы: 'y',
      э: 'e',
      ю: 'yu',
      я: 'ya',
      ї: 'yi',
      і: 'i',
      є: 'ye',
      ґ: 'g',
    };

    let transliteratedFileName = fileName
      .split('')
      .map(char => {
        if (transliterationMap[char]) {
          return transliterationMap[char];
        } else if (char === ' ') {
          return '_';
        } else if (/^[a-zA-Z0-9\-_.]$/.test(char)) {
          return char;
        } else {
          return '';
        }
      })
      .join('');

    return transliteratedFileName;
  };

  const uploadImage = async () => {
    try {
      const formData = new FormData();
      const sanitizedFileName = transliterateAndSanitize(selectedImage.name);
      const file = new File([selectedImage], sanitizedFileName, {
        type: selectedImage.type,
      });
      formData.append('file', file);

      console.log(file);

      const response = await axios.post(
        `${apiBaseUrl}/api/upload-to-backblaze/chat?bucket_name=chatall`,
        formData
      );

      if (response && response.data) {
        const imageUrl = response.data;
        // const imageUrl = response.data.public_url;
        setSelectedImage(imageUrl);
        console.log(imageUrl);
        return imageUrl;
      } else {
        console.error('Failed to upload image');
        return null;
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };

  const handleImageSend = async () => {
    if (!selectedImage) {
      console.error('No image selected.');
      return;
    }
    if (isImageSending) {
      console.log('Image is already being sent.');
      return;
    }

    setIsImageSending(true);

    try {
      const imageUrl = await uploadImage(selectedImage);

      if (!imageUrl) {
        console.error('Failed to upload image.');
        return;
      }

      if (
        socketRef.current &&
        socketRef.current.readyState === WebSocket.OPEN
      ) {
        const messageObject = {
          send: {
            original_message_id: null,
            message: imageText || null,
            fileUrl: imageUrl,
          },
        };

        const messageString = JSON.stringify(messageObject);
        socketRef.current.send(messageString);

        setSelectedImage(null);
        setSelectedFilesCount(0);
        setImageText('');
      } else {
        console.error('WebSocket is not open. Message not sent.');
      }
    } catch (error) {
      console.error('Error sending image:', error);
    } finally {
      setIsImageSending(false);
    }
  };

  const handleImageChange = event => {
    const files = event.target.files;
    setSelectedFilesCount(files.length);
    const file = files[0];
    if (file) {
      if (file.size <= 25 * 1024 * 1024) {
        setSelectedFilesCount(files.length);
        setSelectedImage(file);
      } else {
        alert('Selected file is too large. Please select a file up to 25 MB.');
        setSelectedFilesCount(0);
      }
    }
  };
  const handleImageClose = () => {
    setSelectedImage(null);
    setSelectedFilesCount(0);
  };

  const handleMouseEnter = id => {
    setHoveredMessageId(id);
  };

  const handleMouseLeave = () => {
    setHoveredMessageId(null);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (editingMessageId) {
        handleEditMessageSend();
      } else {
        handleChatMessageSend();
      }
    }
  };

  const handleSelectReplyMessage = (
    messageId,
    messageText,
    messageSender,
    fileUrl
  ) => {
    setSelectedReplyMessageId(messageId);
    setSelectedReplyMessageText(messageText);
    setSelectedReplyMessageSender(messageSender);
    setselectedReplyMessageImage(fileUrl);
  };

  const handleSendReply = async replyMessage => {
    console.log(replyMessage);

    if (!token) {
      openLoginModal();
      return;
    }

    let fileUrl = null;

    if (selectedImage) {
      fileUrl = await uploadImage(selectedImage);
      console.log(fileUrl);
      if (!fileUrl) {
        console.error('Failed to upload file.');
        return;
      }
    }

    if (!replyMessage.trim() && !fileUrl) {
      console.log('Both reply message and file are empty. Not sending reply.');
      return;
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const replyData = {
        send: {
          original_message_id: selectedReplyMessageId,
          message: replyMessage,
          fileUrl: fileUrl,
        },
      };
      console.log('Preparing to send reply:', replyData);

      try {
        const messageString = JSON.stringify(replyData);
        socketRef.current.send(messageString);
        console.log('Reply successfully sent.');
      } catch (error) {
        console.error('Error sending message:', error);
      } finally {
        // Сброс состояния после отправки
        setSelectedImage(null);
        setSelectedFilesCount(0);
        setShouldScrollToBottom(true);
        setImageText('');
      }
    } else {
      console.error('WebSocket is not open. Reply message not sent.');
    }
  };

  const handleChatMessageSend = () => {
    if (selectedReplyMessageId) {
      console.log(message);

      handleSendReply(message);
      setSelectedReplyMessageId(null);
      setSelectedReplyMessageText(null);
    } else {
      sendMessage();
    }
    setMessage('');
  };

  const handleDeleteMessage = messageId => {
    if (!token) {
      openLoginModal();
      return;
    }

    const deleteMessageObject = {
      delete: {
        id: messageId,
      },
    };

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const deleteMessageString = JSON.stringify(deleteMessageObject);
      socketRef.current.send(deleteMessageString);
    } else {
      console.error('WebSocket is not open. Delete message not sent.');
    }
    // setMessages(prevMessages =>
    //   prevMessages.filter(msg => msg.id !== messageId)
    // );
  };

  const handleEditMessageClick = (editedMsg, messageId) => {
    console.log(editedMessage, messageId);
    setEditingMessageId(messageId);
    setEditedMessage(editedMsg);
  };

  const handleEditMessageSend = () => {
    if (!token) {
      openLoginModal();
      return;
    }

    if (!editedMessage.trim()) {
      console.log('Edited message is empty. Not sending edit.');
      return;
    }

    const editMessageObject = {
      update: {
        id: editingMessageId,
        message: editedMessage,
      },
    };

    console.log(editMessageObject);

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const editMessageString = JSON.stringify(editMessageObject);
      socketRef.current.send(editMessageString);
    } else {
      console.error('WebSocket is not open. Edit message not sent.');
      return;
    }

    setEditingMessageId(null);
    setEditedMessage('');
    setMessage('');
  };

  const handleCancelEdit = () => {
    setEditingMessageId(null);
    setEditedMessage('');
  };

  const imageMessages = messages.filter(msg => {
    return msg.fileUrl && msg.fileUrl.match(/\.(jpeg|jpg|gif|png|webp)$/);
  });

  const getFileType = fileUrl => {
    const extension = getFileExtension(fileUrl).toLowerCase();

    if (isImageExtension(extension)) {
      return 'image';
    } else if (isVideoExtension(extension)) {
      return 'video';
    } else if (isDocumentExtension(extension)) {
      return 'document';
    } else if (isAudioExtension(extension)) {
      return 'audio';
    } else {
      return { extension };
    }
  };

  const getFileExtension = fileUrl => {
    const urlWithoutLastCharacter = fileUrl.endsWith('?')
      ? fileUrl.slice(0, -1)
      : fileUrl;
    return urlWithoutLastCharacter.split('.').pop();
  };

  const isImageExtension = extension => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    return imageExtensions.includes(extension);
  };

  const isVideoExtension = extension => {
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv'];
    return videoExtensions.includes(extension);
  };

  const isAudioExtension = extension => {
    const audioExtensions = ['mp3', 'wav', 'ogg', 'm4a', 'webm', '.aac'];
    const audioMimeTypes = [
      'audio/mpeg', // для mp3
      'audio/wav', // для wav
      'audio/ogg', // для ogg
      'audio/x-m4a', // для m4a
      'audio/webm', // для webm
      'audio/webm;codecs=opus', // для webm с кодеком opus
    ];

    return (
      audioExtensions.includes(extension) || audioMimeTypes.includes(extension)
    );
  };
  const isDocumentExtension = extension => {
    const documentExtensions = [
      'pdf',
      'doc',
      'docx',
      'ppt',
      'pptx',
      'xls',
      'xlsx',
    ];
    return documentExtensions.includes(extension);
  };

  const extractFileNameFromUrl = fileUrl => {
    let fileName = fileUrl.split('/').pop();
    if (fileName.endsWith('?')) {
      fileName = fileName.slice(0, -1);
    }
    return fileName.split('?')[0];
  };

  const renderFile = fileUrl => {
    const fileType = getFileType(fileUrl);
    // console.log(fileType);

    switch (fileType) {
      case 'image':
        return (
          <img
            src={fileUrl}
            alt="Uploaded"
            className={css.imageInChat}
            onClick={e => {
              e.stopPropagation();
              setIsImageModalOpen(true);
              setSelectedImageUrl(fileUrl);
            }}
          />
        );
      case 'video':
        return (
          <video
            className={css.imageInChat}
            onClick={e => {
              e.stopPropagation();
            }}
            controls
          >
            <source src={fileUrl} type={`video/${getFileExtension(fileUrl)}`} />
          </video>
        );
      case 'document':
        const fileName = extractFileNameFromUrl(fileUrl);
        return (
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            <svg
              width="36"
              height="48"
              className={css.docInChat}
              viewBox="0 0 36 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M21 12.75V0H2.25C1.00312 0 0 1.00312 0 2.25V45.75C0 46.9969 1.00312 48 2.25 48H33.75C34.9969 48 36 46.9969 36 45.75V15H23.25C22.0125 15 21 13.9875 21 12.75ZM28.1672 32.565L19.1278 41.5369C18.5044 42.1566 17.4975 42.1566 16.8741 41.5369L7.83469 32.565C6.88313 31.6209 7.55062 30 8.88937 30H15V22.5C15 21.6712 15.6712 21 16.5 21H19.5C20.3288 21 21 21.6712 21 22.5V30H27.1106C28.4494 30 29.1169 31.6209 28.1672 32.565ZM35.3438 9.84375L26.1656 0.65625C25.7438 0.234375 25.1719 0 24.5719 0H24V12H36V11.4281C36 10.8375 35.7656 10.2656 35.3438 9.84375Z" />
            </svg>
            <p>{fileName}</p>
          </a>
        );
      case 'audio':
        return (
          <audio controls className={css.audioBg}>
            <source src={fileUrl} type={`audio/${getFileExtension(fileUrl)}`} />
          </audio>
          // <AudioPlayer src={fileUrl} />
        );
      default:
        return <p>{fileUrl}</p>;
    }
  };

  const handleDeleteRoom = () => {
    if (!roomId) {
      console.error('Room ID is not valid');
      return;
    }
    axios
      .delete(`${apiBaseUrl}/api/rooms/${roomId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: '*/*',
        },
      })
      .then(response => {
        console.log('Room deleted successfully:', response.data);
        navigate('/');
      })
      .catch(error => {
        console.error('Error deleting room:', error);
        alert('its not your room');
      });
  };

  const scrollToMessage = id => {
    const messageElement = messageRefs.current[id];
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  useEffect(() => {
    // При изменении selectedReplyMessageId автоматически прокручиваем к нужному сообщению
    if (selectedReplyMessageId) {
      scrollToMessage(selectedReplyMessageId);
    }
  }, [selectedReplyMessageId]);

  const startRecording = async () => {
    try {
      // Запрашиваем доступ к микрофону только при начале записи
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const recorderInstance = new Recorder(audioContext);

      recorderInstance.init(stream);
      setRecorder(recorderInstance);
      setAudioStream(stream);

      await recorderInstance.start();
      setIsRecording(true);
    } catch (error) {
      console.error(
        'Ошибка при доступе к микрофону или запуске записи:',
        error
      );
      alert('Please allow access to the microphone or check its availability ');
    }
  };

  const stopRecording = async () => {
    if (recorder) {
      const { blob } = await recorder.stop();
      setAudioBlob(blob);
      setIsRecording(false);

      // Останавливаем все дорожки для полного отключения микрофона
      if (audioStream) {
        audioStream.getTracks().forEach(track => track.stop()); // Останавливаем все дорожки
        setAudioStream(null); // Очищаем сохраненный поток
      }
    }
  };

  return (
    <>
      {menuRoomOpen ? (
        <div className={css.menuRoomContainer}>
          <h2>Room Settings Menu</h2>
          <button onClick={handleRoomMenuToggle}>Close Menu</button>
        </div>
      ) : (
        <div className={css.container}>
          <h2 className={css.title}>{roomName}</h2>
          <div className={css.main_container}>
            {userList.length > 0 && (
              <div className={css.members_container}>
                {showGallery ? (
                  <>
                    <h3 className={css.galleryTitle}>Media</h3>
                    <button
                      onClick={() => {
                        setShowGallery(false);
                      }}
                      className={css.closeGalleryButton}
                    >
                      <ButtonCloseGallery />
                    </button>
                    <ul className={css.gallery}>
                      {imageMessages.length > 0 ? (
                        imageMessages.map((msg, index) => (
                          <li key={index} className={css.galleryItem}>
                            <img
                              src={msg.fileUrl}
                              alt="Chat Image"
                              className={css.galleryImage}
                              onClick={e => {
                                e.stopPropagation();
                                setIsImageModalOpen(true);
                                setSelectedImageUrl(msg.fileUrl);
                              }}
                            />
                          </li>
                        ))
                      ) : (
                        <li>No images in this chat.</li>
                      )}
                    </ul>
                  </>
                ) : (
                  <ul className={css.userList}>
                    {userList.map(userData => (
                      <li key={userData.user_name} className={css.userItem}>
                        <img
                          src={userData.avatar}
                          alt={`${userData.user_name}'s Avatar`}
                          className={css.user_avatar}
                        />
                        <span className={css.user_name}>
                          {userData.user_name}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}

            <div className={css.chat_container}>
              {token && (
                <ul className={css.navMenuList}>
                  <li className={css.navMenuItem}>
                    <button
                      onClick={() => setShowGallery(!showGallery)}
                      className={css.navMenuButton}
                    >
                      <ButtonGallery className={css.navMenuIcon} />
                    </button>
                  </li>
                  <li className={css.navMenuItem}>
                    <button
                      // onClick={() => setIsDeleteRoomModalOpen(true)}
                      onClick={handleRoomMenuToggle}
                      className={css.navMenuButton}
                    >
                      <ButtonRoomSettings className={css.navMenuIcon} />
                    </button>
                  </li>
                </ul>
              )}

              <div className={css.chat_area} ref={messageContainerRef}>
                {messages.length === 0 && (
                  <div className={css.no_messages}>
                    <img
                      src={Bg}
                      alt="No messages"
                      className={css.no_messagesImg}
                    />
                    <p className={css.no_messages_text}>
                      Oops... There are no messages here yet. Write first!
                    </p>
                  </div>
                )}

                {messages.map((msg, index) => (
                  <div
                    key={index}
                    ref={el => (messageRefs.current[msg.id] = el)}
                    className={`${css.chat_message} ${
                      parseInt(currentUserId) === parseInt(msg.receiver_id)
                        ? css.my_message
                        : ''
                    }`}
                  >
                    <div
                      className={css.chat}
                      onMouseEnter={() => handleMouseEnter(msg.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <img
                        src={msg.avatar}
                        alt={`${msg.sender}'s Avatar`}
                        className={css.chat_avatar}
                        onClick={() =>
                          handleAvatarClick({
                            user_name: msg.sender,
                            avatar: msg.avatar,
                            receiver_id: msg.receiver_id,
                          })
                        }
                      />
                      <div className={css.chat_div}>
                        <div className={css.chat_nicktime}>
                          <span className={css.chat_sender}>
                            {msg.sender}
                            {msg.user_name}
                          </span>
                          <span className={css.time}>
                            {msg.formattedDate}
                            {formatTime(msg.created_at)}
                          </span>
                        </div>
                        {msg.deleted ? (
                          <div
                            className={`${css.messageText} ${
                              parseInt(currentUserId) ===
                              parseInt(msg.receiver_id)
                                ? css.my_message_text
                                : ''
                            }`}
                          >
                            <i>Message deleted</i>
                          </div>
                        ) : msg.message || msg.fileUrl ? (
                          <div
                            className={`${css.messageText} ${
                              parseInt(currentUserId) ===
                              parseInt(msg.receiver_id)
                                ? css.my_message_text
                                : ''
                            }`}
                            onClick={() => setIsChatMenuOpen(msg.id)}
                          >
                            {msg.id_return && msg.id_return !== 0 ? (
                              // Ищем сообщение, на которое ссылается текущий реплай
                              messages.find(
                                message => message.id === msg.id_return
                              ) ? (
                                messages
                                  .filter(
                                    message => message.id === msg.id_return
                                  )
                                  .map((message, index) => (
                                    <div
                                      key={index}
                                      // onClick={() =>
                                      //   scrollToMessage(msg.id_return)
                                      // }
                                    >
                                      <div
                                        // className={css.replyContainerUp}
                                        onClick={() => {
                                          scrollToMessage(msg.id_return);
                                        }}
                                      >
                                        <p className={css.replyMessageUsername}>
                                          {message.sender}
                                        </p>
                                        <div className={css.replyContentUp}>
                                          {message.fileUrl &&
                                            getFileType(message.fileUrl) ===
                                              'image' && (
                                              <img
                                                src={message.fileUrl}
                                                alt="Reply"
                                                className={
                                                  css.ReplyMessageImage
                                                }
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  setIsImageModalOpen(true);
                                                  setSelectedImageUrl(
                                                    message.fileUrl
                                                  );
                                                }}
                                              />
                                            )}
                                          {message.fileUrl &&
                                            getFileType(message.fileUrl) ===
                                              'document' && (
                                              <a
                                                href={message.fileUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <svg
                                                  width="36"
                                                  height="48"
                                                  className={css.docInChat}
                                                  viewBox="0 0 36 48"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path d="M21 12.75V0H2.25C1.00312 0 0 1.00312 0 2.25V45.75C0 46.9969 1.00312 48 2.25 48H33.75C34.9969 48 36 46.9969 36 45.75V15H23.25C22.0125 15 21 13.9875 21 12.75ZM28.1672 32.565L19.1278 41.5369C18.5044 42.1566 17.4975 42.1566 16.8741 41.5369L7.83469 32.565C6.88313 31.6209 7.55062 30 8.88937 30H15V22.5C15 21.6712 15.6712 21 16.5 21H19.5C20.3288 21 21 21.6712 21 22.5V30H27.1106C28.4494 30 29.1169 31.6209 28.1672 32.565ZM35.3438 9.84375L26.1656 0.65625C25.7438 0.234375 25.1719 0 24.5719 0H24V12H36V11.4281C36 10.8375 35.7656 10.2656 35.3438 9.84375Z" />
                                                </svg>
                                              </a>
                                            )}
                                          {message.fileUrl &&
                                            getFileType(message.fileUrl) ===
                                              'video' && (
                                              <video
                                                className={css.imageInChat}
                                                controls
                                              >
                                                <source
                                                  src={message.fileUrl}
                                                  type={`video/${getFileExtension(
                                                    message.fileUrl
                                                  )}`}
                                                />
                                                Your browser does not support
                                                the video tag.
                                              </video>
                                            )}
                                          {message.fileUrl &&
                                            getFileType(message.fileUrl) ===
                                              'audio' && <i>audio msg</i>}
                                          {message.message && (
                                            <p className={css.replyMessageText}>
                                              {message.message}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        className={css.replyContentDown}
                                        // onClick={() =>
                                        //   setIsChatMenuOpen(msg.id)
                                        // }
                                      >
                                        {msg.fileUrl && (
                                          <img
                                            src={msg.fileUrl}
                                            alt="Reply"
                                            className={css.ReplyMessageImage}
                                            onClick={e => {
                                              e.stopPropagation();
                                              setIsImageModalOpen(true);
                                              setSelectedImageUrl(msg.fileUrl);
                                            }}
                                          />
                                        )}
                                        <p className={css.messageTextReply}>
                                          {msg.message}
                                        </p>
                                        {msg.edited && (
                                          <span className={css.editedText}>
                                            edited
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ))
                              ) : (
                                // Если оригинальное сообщение удалено
                                <div onClick={() => setIsChatMenuOpen(msg.id)}>
                                  <p className={css.replyMessageUsername}>
                                    {message.sender}
                                  </p>
                                  <div className={css.replyContentUp}>
                                    <p className={css.replyMessageText}>
                                      Deleted Message
                                    </p>
                                  </div>
                                  <div className={css.replyContentDown}>
                                    {msg.fileUrl && (
                                      <img
                                        src={msg.fileUrl}
                                        alt="Reply"
                                        className={css.ReplyMessageImage}
                                      />
                                    )}
                                    <p className={css.messageTextReply}>
                                      {msg.message}
                                    </p>
                                    {msg.edited && (
                                      <span className={css.editedText}>
                                        edited
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )
                            ) : (
                              <div onClick={() => setIsChatMenuOpen(msg.id)}>
                                {msg.fileUrl && renderFile(msg.fileUrl)}
                                {msg.message && <p>{msg.message}</p>}
                                {msg.edited && (
                                  <span className={css.editedText}>edited</span>
                                )}
                              </div>
                            )}
                          </div>
                        ) : null}

                        <div className={css.actions}>
                          {!msg.deleted &&
                            (msg.vote > 0 || hoveredMessageId === msg.id) && (
                              <div
                                className={css.likeContainer}
                                onClick={() => handleLikeClick(msg.id)}
                              >
                                <LikeSVG className={css.like} />
                                {msg.vote !== 0 && <span>{msg.vote}</span>}
                              </div>
                            )}
                        </div>
                      </div>

                      {isChatMenuOpen === msg.id && (
                        <div
                          id={`chat-menu-container-${msg.id}`}
                          className={css.chatMenuContainer}
                        >
                          {parseInt(currentUserId) ===
                            parseInt(msg.receiver_id) && (
                            <div className={css.chatMenuList}>
                              <button
                                className={css.chatMenuMsgButton}
                                onClick={() => {
                                  handleSelectReplyMessage(
                                    msg.id,
                                    msg.message,
                                    msg.sender,
                                    msg.fileUrl
                                  );
                                  handleCloseChatMenu();
                                }}
                              >
                                <ReplySvg />
                                Reply
                              </button>
                              <button
                                className={css.chatMenuMsgButton}
                                onClick={() => {
                                  handleEditMessageClick(msg.message, msg.id);
                                  handleCloseChatMenu();
                                }}
                              >
                                <EditSvg />
                                Edit
                              </button>
                              <button
                                className={css.chatMenuMsgButton}
                                onClick={() => {
                                  handleDeleteMessage(msg.id);
                                  handleCloseChatMenu();
                                }}
                              >
                                <DeleteSvg />
                                Delete
                              </button>
                            </div>
                          )}
                          {parseInt(currentUserId) !==
                            parseInt(msg.receiver_id) && (
                            <div className={css.chatMenuList}>
                              <button
                                className={css.chatMenuMsgButton}
                                onClick={() => {
                                  handleSelectReplyMessage(
                                    msg.id,
                                    msg.message,
                                    msg.sender,
                                    msg.fileUrl
                                  );
                                  handleCloseChatMenu();
                                }}
                              >
                                <ReplySvg />
                                Reply
                              </button>
                            </div>
                          )}
                          <button
                            className={css.chatMenuClose}
                            onClick={handleCloseChatMenu}
                          >
                            X
                          </button>
                        </div>
                      )}
                      {showSVG && (
                        <div className={css.svg_container}>
                          <AnimatesTypingSVG className={css.wave} />
                          <ShowTypingSVG />
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                {selectedUser && (
                  <div className={css.userMenu}>
                    <p>Write a direct message to {selectedUser.user_name}</p>
                    <button onClick={handleDirectMessageClick}>
                      Write a direct message
                    </button>
                    <button onClick={handleCloseMenu}>Close</button>
                  </div>
                )}
              </div>
              {selectedReplyMessageId && (
                <div className={css.replyContainer}>
                  <IconReplySVG />
                  <div className={css.replyContainerFlex}>
                    <p className={css.replyMessageUsername}>
                      Reply to {selectedReplyMessageSender}
                    </p>
                    <div className={css.replyContainerImgText}>
                      {selectedReplyMessageImage && (
                        <div>
                          <img
                            src={selectedReplyMessageImage}
                            alt="Reply"
                            className={css.replyImage}
                          />
                        </div>
                      )}
                      {selectedReplyMessageText && (
                        <p className={css.chatTextReply}>
                          {selectedReplyMessageText}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={css.buttons}>
                    <ButtonReplyCloseSVG
                      onClick={handleCloseReply}
                      className={css.svgCloseReply}
                    />
                  </div>
                </div>
              )}
              {selectedImage && selectedImage.name && (
                <div className={css.imgContainerUpload}>
                  <div className={css.imgUploadDiv}>
                    {isImageExtension(
                      selectedImage.name.toLowerCase().split('.').pop()
                    ) ? (
                      <img
                        className={css.imgUpload}
                        src={URL.createObjectURL(selectedImage)}
                        alt={`Preview`}
                        onClick={() => {
                          setIsImageModalOpen(true);
                          setSelectedImageUrl(
                            URL.createObjectURL(selectedImage)
                          );
                        }}
                      />
                    ) : isDocumentExtension(
                        selectedImage.name.toLowerCase().split('.').pop()
                      ) ? (
                      <a
                        href={URL.createObjectURL(selectedImage)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          width="36"
                          height="48"
                          className={css.docInChat}
                          viewBox="0 0 36 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M21 12.75V0H2.25C1.00312 0 0 1.00312 0 2.25V45.75C0 46.9969 1.00312 48 2.25 48H33.75C34.9969 48 36 46.9969 36 45.75V15H23.25C22.0125 15 21 13.9875 21 12.75ZM28.1672 32.565L19.1278 41.5369C18.5044 42.1566 17.4975 42.1566 16.8741 41.5369L7.83469 32.565C6.88313 31.6209 7.55062 30 8.88937 30H15V22.5C15 21.6712 15.6712 21 16.5 21H19.5C20.3288 21 21 21.6712 21 22.5V30H27.1106C28.4494 30 29.1169 31.6209 28.1672 32.565ZM35.3438 9.84375L26.1656 0.65625C25.7438 0.234375 25.1719 0 24.5719 0H24V12H36V11.4281C36 10.8375 35.7656 10.2656 35.3438 9.84375Z" />
                        </svg>
                      </a>
                    ) : (
                      <video
                        className={css.imgUpload}
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        controls
                      >
                        <source
                          src={URL.createObjectURL(selectedImage)}
                          type={`video/${selectedImage.name.split('.').pop()}`}
                        />
                      </video>
                    )}
                  </div>
                  <div className={css.imageInfo}>
                    <p>{selectedImage.name}</p>
                    <p>{(selectedImage.size / (1024 * 1024)).toFixed(2)} МБ</p>
                    <input
                      className={css.imgInput}
                      type="text"
                      value={imageText}
                      onChange={e => setImageText(e.target.value)}
                      placeholder="Write text to img"
                    />
                  </div>
                  <div className={css.buttons}>
                    <SendImgSVG
                      className={css.sendImg}
                      onClick={handleImageSend}
                    />
                    <ButtonReplyCloseSVG
                      className={css.svgCloseReply}
                      onClick={handleImageClose}
                    />
                  </div>
                </div>
              )}

              <div className={css.input_container}>
                <label htmlFor="message" className={css.input_label}>
                  <input
                    type="text"
                    id="message"
                    value={editingMessageId ? editedMessage : message}
                    onChange={handleMessageChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Write message"
                    className={css.input_text}
                  />
                  <div className={css.voice_message_container}>
                    <button
                      className={css.voice_record_button}
                      onClick={isRecording ? stopRecording : startRecording}
                    >
                      {isRecording ? 'Stop Recording' : 'Record Voice Message'}
                    </button>
                    {audioBlob && (
                      <button
                        className={css.voice_send_button}
                        onClick={() => sendMessage(audioBlob)}
                      >
                        Send Voice Message
                      </button>
                    )}
                  </div>
                  <div className={css.containerFlex}>
                    {editingMessageId && (
                      <ButtonReplyCloseSVG
                        className={css.svgCloseEdit}
                        onClick={handleCancelEdit}
                      />
                    )}
                    <label className={css.file_input_label}>
                      <AddFileSVG className={css.add_file_icon} />
                      {selectedFilesCount > 0 && (
                        <span className={css.selected_files_count}>
                          {selectedFilesCount}
                        </span>
                      )}
                      {/* <input type="file" key={selectedFilesCount} accept="image/*" onChange={handleImageChange}  className={css.file_input} /> */}
                      <input
                        type="file"
                        key={selectedFilesCount}
                        onChange={handleImageChange}
                        className={css.file_input}
                      />
                    </label>
                  </div>
                </label>
                <div className={css.input_container}>
                  {editingMessageId ? (
                    <ButtonSendMessage
                      className={css.button_send}
                      onClick={handleEditMessageSend}
                    />
                  ) : (
                    <ButtonSendMessage
                      className={css.button_send}
                      onClick={handleChatMessageSend}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <LoginModal
            isOpen={isLoginModalOpen}
            onClose={closeLoginModal}
            onRegistrationSuccess={handleRegistrationSuccess}
          />
          <VerificationEmailModal
            isOpen={showVerificationModal}
            onClose={() => setShowVerificationModal(false)}
          />
          <ImageModal
            isOpen={isImageModalOpen}
            imageUrl={selectedImageUrl}
            onClose={() => setIsImageModalOpen(false)}
          />
          <DeleteRoomModal
            isOpen={isDeleteRoomModalOpen}
            onClose={() => setIsDeleteRoomModalOpen(false)}
            onConfirmDelete={handleDeleteRoom}
            roomName={roomName}
          />
        </div>
      )}
    </>
  );
};

export default Chat;
